// Vars Color
$main:#657fbf;
$support1:#24B5B9;
$support2:#3B5976;
$white:#FFF ;
$white-sup:#F8F8F8;
$light-black:#495057;
$light-blue:#E9ECEF;
$dark-black:#212529;
$dark:#022E46;
$green:#52CEAA;
$light-red: #C95656;
$red: #FA3E6B;
$yellow: #FFC02D;
$light-white: #F8F9FA;
$blue: #0d6efd;
$light-grey: #707070;
$dark-grey: #495057;
$grey: #6C757D;
$black:#0000;
$dark-blue:#24B5B9;
// START === Background Color
.bg-Inherit {
  background-color: inherit;
}
.bg-transparent {
  background-color: transparent !important;
}
.bg-main {
  background-color: $main !important;
}
.bg-white-sup {
  background-color: $white-sup;
}
.bg-support1 {
  background-color: $support1;
}
.bg-support2 {
  background-color: $support2;
}
.bg--white {
  background-color: $white ;
}
.bg-light-white {
  background-color: $light-white;
}
.bg-light-grey {
  background-color: $light-grey;
}
.bg-yellow {
  background-color: $yellow;
}
.bg-green {
  background-color: $green;
}
.bg-grey {
  background-color: $grey;
}
.bg-dark {
  background-color: $dark !important;
}
.bg-light-blue {
  background-color: $light-blue !important;
}
// END === Background Color
// START === Text Color
.text-main-color {
  color: $main;
}
.text-white-sup-color {
  color: $white-sup;
}
.text-support1-color {
  color: $support1;
}
.text-support2-color {
  color: $support2;
}
.text-white-color {
  color: $white;
}
.text-light-black-color{
  color: $light-black;
}
.text-red-color{
  color: $red;
}
.text-light-red-color{
  color: $light-red;
}
.text-blue-color{
  color: $blue;
}
.text-yellow{
  color: $yellow;
}
.text-green{
  color: $green;
}
.text-grey{
  color: $grey;
}
.text-light-grey{
  color: $light-grey;
}
.text-dark-black{
  color: $dark-black;
}
.text-light-black{
  color: $light-black;
}
.text-light-blue{
  color: $light-blue;
}
.text-dark-color{
  color: $dark;
}
.text-black{
  color: $black;
}
.text-dark-grey{
  color: $dark-grey;
}
.text-grey-color{
  color: $grey;
}
.text-dark-blue{
  color: $dark-blue;
}
// END === Text Color

//backgrounds with opacity
.bg-support2-opacity-10{
  background-color: rgb(59, 89, 118 , 0.1);
}
.bg-main-opacity-10{
  background-color: rgba(30, 170, 173, 0.1);
}
.bg-main-10{
  background-color: rgba($color: $main, $alpha: 0.1);
}
.bg-support2-opacity-100{
  background-color: rgb(2, 46, 70)
}
.bg-light-blue-1{
  background-color: rgba($color: $light-blue, $alpha: 0.1)
}
.bg-support1-01{
  background-color: rgba($color: $support1, $alpha: 0.1)
}
// background with gradient
.bg-main-gredient{
  background: linear-gradient(93.25deg, #24B5B9 3.34%, #657FBF 98.45%);
}
