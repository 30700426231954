// START === Images
// navbar icons
.ic-Logo-Nav {
  background-image: url(../assets/Icons/main-logo.webp);
}
.ic-Logo-Footer {
  background-image: url(../assets/logo/main-logo-footer.ccfcc9d30c4859cb7f72.svg);
}
.ic-message {
  background-image: url(../assets/Icons/message.svg);
}
.ic-nav-notification {
  background-image: url(../assets/Icons/notification.svg);
}
.ic-personal-account {
  background-image: url(../assets/Icons/Personal-account.svg);
}
.ic-company-account {
  background-image: url(../assets/Icons/Company-account.svg);
}
.ic-arrow-down {
  background-image: url(../assets/Icons/arrow-down.svg);
}
.ic-user-profile {
  background-image: url(../assets/Icons/user-profile.svg);
}
.ic-star-profile {
  background-image: url(../assets/Icons/profile/star.svg);
}
.ic-cv {
  background-image: url(../assets/Icons/cv.svg);
}
.ic-sign-out {
  background-image: url(../assets/Icons/sign-out-alt.svg);
}
.ic-settings {
  background-image: url(../assets/Icons/navbar/settings.svg);
}
//START === navbar icons for Responsive
.ic-menu-button {
  background-image: url(../assets/Icons/Responsive/menu-button.svg);
}
.ic-profile {
  background-image: url(../assets/Icons/Responsive/profile.svg);
}
// home
.ic-dollar {
  background-image: url(../assets/Icons/home/dollar.svg);
}
.ic-user-1 {
  background-image: url(../assets/Icons/home/user-1.svg);
}
.ic-user-2 {
  background-image: url(../assets/Icons/home/user-2.svg);
}
.ic-user-3 {
  background-image: url(../assets/Icons/home/user-3.svg);
}
.ic-user-4 {
  background-image: url(../assets/Icons/home/user-4.svg);
}
.ic-user-5 {
  background-image: url(../assets/Icons/home/user-5.svg);
}
.ic-user-6 {
  background-image: url(../assets/Icons/home/user-6.svg);
}
.ic-earth-americas {
  background-image: url(../assets/Icons/home/earth-americas.svg);
}
.ic-plane-paper {
  background-image: url(../assets/Icons/home/plane-paper.svg);
}
.ic-shield-check {
  background-image: url(../assets/Icons/home/shield-check.svg);
}
.ic-star-comment-alt {
  background-image: url(../assets/Icons/home/star-comment-alt.svg);
}
.ic-time-twenty-four {
  background-image: url(../assets/Icons/home/time-twenty-four.svg);
}
.ic-user-check {
  background-image: url(../assets/Icons/home/user-check.svg);
}
.ic-user-check {
  background-image: url(../assets/Icons/home/user-check.svg);
}
.ic-filters {
  background-image: url(../assets/Icons/home/filters.svg);
}
.ic-graduation-cap {
  background-image: url(../assets/Icons/home/graduation-cap.svg);
}
.ic-user-check-white {
  background-image: url(../assets/Icons/home/user-check-white.svg);
}
.ic-apps {
  background-image: url(../assets/Icons/home/apps.svg);
}
.ic-arrow-right-white {
  background-image: url(../assets/Icons/home/arrow-right-white.svg);
}
.ic-diploma-white {
  background-image: url(../assets/Icons/home/diploma-white.svg);
}
.ic-paper-plane-white {
  background-image: url(../assets/Icons/home/paper-plane-white.svg);
}
.ic-calendar-clock-home {
  background-image: url(../assets/Icons/home/calendar-clock-home.svg);
}
.ic-phone-home {
  background-image: url(../assets/Icons/home/phone-home.svg);
}
.ic-envelope-home {
  background-image: url(../assets/Icons/home/envelope-home.svg);
}

// freelancer reg
.ic-eyes {
  background-image: url(../assets/Icons/eyes-pass.svg);
}
.ic-hidden {
  background-image: url(../assets/Icons/hidden.svg);
}
.ic-remove {
  background-image: url(../assets/Icons/remove.svg);
}
.ic-arrow-down {
  background-color: #1eaaad;
  border: 8px solid #1eaaad;
  -webkit-mask: url(../assets/Icons/arrow-down.svg) no-repeat center;
  mask: url(../assets/Icons/arrow-down.svg) no-repeat center;
}
.ic-down-arrow {
  background-image: url(../assets/Icons/down-arrow.svg);
}
.ic-arrow-left {
  background-image: url(../assets/Icons/arrow-left.svg);
}
.ic-plus {
  background-image: url(../assets/Icons/plus.svg);
}
.ic-camera {
  background-image: url(../assets/Icons/camera.svg);
}
.ic-user {
  background-image: url(../assets/Icons/user.svg);
}
.ic-user-placeholder {
  background-image: url(../assets/Icons/chat/user-placeholder.svg);
}
// new navbar
.ic-Home-icon {
  background-image: url(../assets/Icons/navbar/Home.svg);
}
.ic-Browse-icon {
  background-image: url(../assets/Icons/navbar/Browse.svg);
}
.ic-Business-Time-icon {
  background-image: url(../assets/Icons/navbar/Business-Time.svg);
}
.ic-Megaphone-icon {
  background-image: url(../assets/Icons/navbar/Megaphone.svg);
}
.ic-Note-icon {
  background-image: url(../assets/Icons/navbar/Note.svg);
}
.ic-User-Placeholder-icon {
  background-image: url(../assets/Icons/navbar/User-Placeholder.png);
}
.ic-Navbar-Arrow-Down-icon {
  background-image: url(../assets/Icons/navbar/Navbar-Arrow-Down.svg);
}
.ic-Add-icon {
  background-image: url(../assets/Icons/navbar/Add.svg);
}
.ic-User-icon {
  background-image: url(../assets/Icons/navbar/user.svg);
}
.ic-Signout-icon {
  background-image: url(../assets/Icons/navbar/Signout.svg);
}
.ic-nav-notification {
  background-image: url(../assets/Icons/navbar/notification.svg);
}
.ic-nav-messages {
  background-image: url(../assets/Icons/navbar/messages.svg);
}
.ic-nav-messages-dot{
  background-image: url(../assets/Icons/navbar/messages-dot.svg);
}
// new footer
.ic-Footer-Image-icon {
  background-image: url(../assets/Icons/Footer/Footer-Image.svg);
}
.ic-Footer-Twitter-icon {
  background-image: url(../assets/Icons/Footer/Footer-Twitter.svg);
}
.ic-Footer-Facebook-icon {
  background-image: url(../assets/Icons/Footer/Footer-Facebook.svg);
}
.ic-Footer-Instagram-icon {
  background-image: url(../assets/Icons/Footer/Footer-Instagram.svg);
}
.ic-App-Store-icon {
  background-image: url(../assets/Icons/Footer/App-Store.svg);
}
.ic-Google-Play-icon {
  background-image: url(../assets/Icons/Footer/Google-Play.svg);
}
//END === navbar icons for Responsive
// START === snackbar images
.ic-error-red {
  background-image: url(../assets/Icons/close-red.svg);
}
.ic-right-green {
  background-image: url(../assets/Icons/right-green.svg);
}
.ic-warning-orange {
  background-image: url(../assets/Icons/warning-orange.svg);
}
// START === snackbar images
// START === Footer Icons
.ic-Snapchat {
  background-image: url(../assets/Icons/Snapchat.9c5941028cc1ce5c0b80.svg);
}
.ic-Instagram {
  background-image: url(../assets/Icons/Instagram.a693c52bcd81f72e1ec7.svg);
}
.ic-Twitter {
  background-image: url(../assets/Icons/Twitter.64b286f8a0ef2795150d.svg);
}
.ic-Facebook {
  background-image: url(../assets/Icons/Facebook.a1f3d962216196dabeb2.svg);
}
.ic-Footer-Shape {
  background-image: url(../assets/Icons/footer-shape.49c1189b36329f85c7bc.webp);
}
.ic-Sub-Footer-Shape {
  background-image: url(../assets/Icons/sub-footer.png);
}
// END === Footer Icons
// start === all-req Icons
.ic-all-request-Vector {
  background-image: url(../assets/Icons/all-request/Vector.svg);
}
.ic-all-request-save {
  background-image: url(../assets/Icons/all-request/save.svg);
}
.ic-all-request-time {
  background-image: url(../assets/Icons/all-request/time.svg);
}
.ic-all-request-location {
  background-image: url(../assets/Icons/all-request/location.svg);
}
.ic-all-request-work {
  background-image: url(../assets/Icons/all-request/work.svg);
}
.ic-all-request-arrow {
  background-image: url(../assets/Icons/all-request/arrow-down.svg);
}
.ic-all-request-map {
  background-image: url(../assets/Icons/all-request/map.svg);
}
.ic-all-request-live-location {
  background-image: url(../assets/Icons/all-request/live-location.svg);
}
.ic-all-request-bag {
  background-image: url(../assets/Icons/all-request/bag.svg);
}
.ic-all-request-star {
  background-image: url(../assets/Icons/all-request/star.svg);
}
.ic-all-request-star-routing1 {
  background-image: url(../assets/Icons/all-request/starRouting1.svg);
}
.ic-all-request-star-routing2 {
  background-image: url(../assets/Icons/all-request/starRouting2.svg);
}
.ic-all-request-flag-saudi {
  background-image: url(../assets/Icons/all-request/saudi-arabia1.svg);
}

.ic-all-request-Evaluation {
  background-image: url(../assets/Icons/all-request/Evaluation.svg);
}
.ic-all-request-diamond {
  background-image: url(../assets/Icons/all-request/daimond.svg);
}
.ic-all-request-status {
  background-image: url(../assets/Icons/all-request/stutas.svg);
}
.ic-all-request-switch {
  background-image: url(../assets/Icons/all-request/switch.svg);
}
.ic-all-request-price {
  background-image: url(../assets/Icons/all-request/price.svg);
}
.ic-all-request-yellow-star {
  background-image: url(../assets/Icons/all-request/yellow-star.svg);
}
.ic-all-request-star {
  background-image: url(../assets/Icons/all-request/star-3.svg);
}
.ic-all-request-arrow-left-bold {
  background-image: url(../assets/Icons/all-request/arrow-left-bold.svg);
}
// END === all-req Icons

//START all-ads

.ic-all-ads-coin {
  background-image: url(../assets/Icons/all-request/coin.svg);
}
.ic-all-ads-bulb {
  background-image: url(../assets/Icons/all-request/bulb.svg);
}
.ic-all-ads-default-image {
  background-image: url(../assets/image/ads-image.svg);
}

//END all-ads


// START == Request Details
.ic-request-details-clock {
  background-image: url(../assets/Icons/request-details/Clock.svg);
}
.ic-request-details-location {
  background-image: url(../assets/Icons/request-details/Side-Location.svg);
}
.ic-request-details-bag {
  background-image: url(../assets/Icons/request-details/bag.svg);
}

.ic-request-details-Paper-Plane {
  background-image: url(../assets/Icons/request-details/Paper-Plane.svg);
}
.ic-request-details-Paper-Plane-hover {
  background-image: url(../assets/Icons/request-details/paper-plane-hover.svg);
}
.ic-request-details-Official-Email {
  background-image: url(../assets/Icons/request-details/Official-Email.svg);
}
.ic-request-details-Phone-Call {
  background-image: url(../assets/Icons/request-details/Phone-Call.svg);
}
.ic-request-details-Whatsapp {
  background-image: url(../assets/Icons/request-details/Whatsapp.svg);
}
.ic-request-details-Share {
  background-image: url(../assets/Icons/request-details/Share.svg);
}
.ic-request-details-Diploma {
  background-image: url(../assets/Icons/request-details/Diploma.svg);
}
.ic-request-details-Three-Dots {
  background-image: url(../assets/Icons/request-details/Three-Dots.svg);
}
.ic-request-details-Docs {
  background-image: url(../assets/Icons/request-details/doc.svg);
}
.ic-request-details-Pdf {
  background-image: url(../assets/Icons/request-details/pdf.svg);
}
.ic-request-details-Xls {
  background-image: url(../assets/Icons/request-details/xls.svg);
}
.ic-request-details-other-documents {
  background-image: url(../assets/Icons/request-details/other-documents.svg);
}
.ic-request-details-Undraw-Done {
  background-image: url(../assets/Icons/request-details/undraw-Done.svg);
}
.ic-request-white-logo {
  background-image: url(../assets/Icons/all-request/white-logo.svg);
}
.ic-request-details-Edit-Request {
  background-image: url(../assets/Icons/request-details/Edit-Request.svg);
}
.ic-request-details-Forbidden-Request {
  background-image: url(../assets/Icons/request-details/Forbidden-Request.svg);
}
.ic-request-details-Delete-Request {
  background-image: url(../assets/Icons/request-details/Delete-Request.svg);
}
.ic-no-documents {
  background-image: url(../assets/Icons/regesteration/no-documents.svg);
}
.ic-Rs-Icon {
  background-image: url(../assets/Icons/request-details/Rs.svg);
}
.ic-clock-grey-Icon {
  background-image: url(../assets/Icons/request-details/time-fast.svg);
}
.ic-location-grey-Icon {
  background-image: url(../assets/Icons/request-details/Location_Grey.svg);
}
.ic-download {
  background-image: url(../assets/Icons/all-request/download.svg);
}
.ic-no-request {
  background-image: url(../assets/Icons/request-details/no-offers.svg);
}
// all requests
.ic-request-location-work {
  background-image: url(../assets/Icons/all-request/location-work.svg);
}
.ic-request-favorite {
  background-image: url(../assets/Icons/all-request/favorite.svg);
}
.ic-request-rmotli-work {
  background-image: url(../assets/Icons/all-request/rmotli-work.svg);
}
.ic-request-visa {
  background-image: url(../assets/Icons/all-request/VISA.svg);
}
.ic-request-cash {
  background-image: url(../assets/Icons/all-request/cash.svg);
}
.ic-request-upload {
  background-image: url(../assets/Icons/all-request/upload.svg);
}
.ic-request-XLS {
  background-image: url(../assets/Icons/all-request/xls.svg);
}
.ic-request-DOC {
  background-image: url(../assets/Icons/all-request/doc.svg);
}
.ic-request-PDF {
  background-image: url(../assets/Icons/all-request/pdf.svg);
}
.ic-request-image {
  background-image: url(../assets/Icons/all-request/image.svg);
}
.ic-request-video {
  background-image: url(../assets/Icons/all-request/video.svg);
}
.ic-home-login {
  background-image: url(../assets/Icons/all-request/home-care.svg);
}
.ic-people-login {
  background-image: url(../assets/Icons/all-request/people.svg);
}
.ic-setting-login {
  background-image: url(../assets/Icons/all-request/settings-2.svg);
}
.ic-connection-login {
  background-image: url(../assets/Icons/all-request/connection.svg);
}
.ic-hand-login {
  background-image: url(../assets/Icons/all-request/handshake-1.svg);
}
.ic-car-login {
  background-image: url(../assets/Icons/all-request/car-mechanic-1.svg);
}


// registeration new icons
.ic-user-account {
  background-image: url(../assets/Icons/regesteration/user-account.svg);
}
.ic-freelancer-account {
  background-image: url(../assets/Icons/regesteration/freelancer-account.svg);
}
.ic-arrow-small-left {
  background-image: url(../assets/Icons/regesteration/arrow-small-left.svg);
}

// chat icons
.ic-chat-icon {
  background-image: url(../assets/Icons/chat/chat-icon.svg);
}
.ic-caret-up-icon {
  background-image: url(../assets/Icons/chat/caret-up.svg);
}
.ic-caret-down-icon {
  background-image: url(../assets/Icons/chat/caret-down.svg);
}
//profile icon
.ic-profile-online {
  background-image: url(../assets/Icons/profile/online.svg);
}
.ic-profile-memo-circle-check {
  background-image: url(../assets/Icons/profile/ic-profile-memo-circle-check.svg);
}
.ic-profile-pencil {
  background-image: url(../assets/Icons/profile/pencil.svg);
}
.ic-profile-phone-call {
  background-image: url(../assets/Icons/profile/phone.svg);
}
.ic-profile-flag {
  background-image: url(../assets/Icons/profile/flag-prof.svg);
}
.ic-profile-venus {
  background-image: url(../assets/Icons/profile/venus-mars.svg);
}
.ic-profile-envelope {
  background-image: url(../assets/Icons/profile/envelope.svg);
}
.ic-profile-empty-skills {
  background-image: url(../assets/Icons/profile/empty-skills.svg);
}
.ic-profile-empty-languages {
  background-image: url(../assets/Icons/profile/empty-languages.svg);
}
.ic-profile-plus {
  background-image: url(../assets/Icons/profile/plus.svg);
}
.ic-profile-emty-notifications {
  background-image: url(../assets/Icons/profile/emty-notifications.svg);
}
.ic-profile-empty-search {
  background-image: url(../assets/Icons/profile/empty-search.svg);
}
.ic-profile-delete {
  background-image: url(../assets/Icons/profile/delete.svg);
}
.ic-profile-offline {
  background-image: url(../assets/Icons/profile/offline.svg);
}
// chat
.ic-send-icon {
  background-image: url(../assets/Icons/regesteration/send-icon.svg);
}
.ic-send-media {
  background-image: url(../assets/Icons/regesteration/send-media.svg);
}
.ic-forget-password {
  background-image: url(../assets/Icons/regesteration/forget-password.svg);
}
.ic-camera {
  background-image: url(../assets/Icons/regesteration/camera.svg);
}
// categories
.ic-categories-placeholder {
  background-image: url(../assets/Icons/regesteration/categories-placeholder.svg);
}
.ic-cross {
  background-image: url(../assets/Icons/regesteration/cross.svg);
}
.ic-close {
  background-image: url(../assets/Icons/chat/cross-2.svg);
}
// registeration
.ic-office-building {
  background-image: url(../assets/Icons/regesteration/office-building.svg);
}
.ic-forget-password-graph {
  background-image: url(../assets/Icons/regesteration/forget-password-graph.svg);
}
// responsive
.ic-filter-whites-responsive {
  background-image: url(../assets/Icons/Responsive/filter-whitesvg.svg);
}
.ic-bars-staggered {
  background-image: url(../assets/Icons/Responsive/bars-staggered.svg);
}
// END === Icons
// Icons Sizes
.iC-S8 {
  width: 8px;
  height: 8px;
}
.iC-S10 {
  width: 10px;
  height: 10px;
}
.iC-S12 {
  width: 12px;
  height: 12px;
}
.iC-S14 {
  width: 14px;
  height: 14px;
}
.iC-S16 {
  width: 16px;
  height: 16px;
}
.iC-S18 {
  width: 18px;
  height: 18px;
}
.iC-S20 {
  width: 20px;
  height: 20px;
  @media (max-width: 768px) {
    width: 16px !important;
  height: 16px !important;
  }
}
.iC-S24 {
  width: 24px;
  height: 24px;
}
.iC-S30 {
  width: 30px;
  height: 30px;
}
.iC-S32 {
  width: 32px;
  height: 32px;
}
.iC-S36 {
  width: 36px;
  height: 36px;
}
.iC-S40 {
  width: 40px;
  height: 40px;
}
.iC-S44 {
  width: 44px;
  height: 44px;
}
.iC-S45 {
  width: 45px;
  height: 45px;
}
.iC-S48 {
  width: 48px;
  height: 48px;
}
.iC-S58 {
  width: 58px;
  height: 58px;
}
.iC-S64 {
  width: 64px;
  height: 64px;
}
.iC-S80 {
  width: 80px;
  height: 80px;
  @media (max-width: 768px) {
      width: 40px !important;
      height: 40px !important;
    }
}
.iC-S94 {
  width: 94px;
  height: 94px;
}
.iC-S100 {
  width: 100px;
  height: 100px;
}
.iC-S124 {
  width: 124px;
  height: 124px;
}
.iC-S136 {
  width: 136px;
  height: 136px;
}
.iC-S148 {
  width: 148px;
  height: 148px;
}
.iC-S200 {
  width: 200px;
  height: 200px;
}
.iC-S320 {
  width: 320px;
  height: 320px;
}
